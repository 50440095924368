export const customerQuery = `
query customerQuery($input: String!) {
  customer(customerAccessToken: $input) {
    id
    firstName
    lastName
    acceptsMarketing
    email
    phone
    tags
    defaultAddress {
        id
        city
    }
  }
}
    `
