export default ({ event, items }) => {
  if (typeof window === 'undefined' || !window.dataLayer) return

  const totalValue = items.reduce((acc, { price, quantity }) => acc + price * quantity, 0)

  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    event,
    ecommerce: {
      items: items.map(({ productTitle, price, shopifyId, brand, variantTitle, quantity }) => ({
        item_name: productTitle,
        item_id: shopifyId,
        price,
        item_brand: brand,
        item_variant: variantTitle,
        quantity,
      })),
    },
    value: totalValue,
  })
}
