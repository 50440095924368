// Module Import
//---------------------------------
import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby-link'
import { useStaticQuery, graphql } from 'gatsby'
// Component Import
//---------------------------------
import { GatsbyImage } from 'gatsby-plugin-image'
import FacebookIconWhite from '../../../assets/images/icon_facebook_white.svg'
import LinkedIconWhite from '../../../assets/images/icon_linkedIn_white.svg'
import TwitterIconWhite from '../../../assets/images/icon_x_white.svg'
import InstagramIconWhite from '../../../assets/images/icon_instagram_white.svg'
import { formatPhoneNumber } from '../../../helpers/formatPhoneNumber'
import getCalloutLink from '../../../helpers/getCalloutLink'
import LinkAtom from '../../atoms/links/links'
import isOuterLink from '../../../helpers/isOuterLink'
import { brandDarkGrey, brandOrange, brandWhite } from '../../../assets/consts/color'
import { primaryFont } from '../../../assets/consts/fonts'

// Styled Component Declarations
//---------------------------------

// Structural
// ----------//
const GlobalFooterWrapper = styled.footer`
  background-color: ${brandWhite};
  display: flex;
  flex-direction: column;
`

const TopWrapper = styled.div`
  background-color: ${brandOrange};

  .inner {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 16px;
    padding-top: 16px;

    @media only screen and (min-width: 48em) {
      align-items: center;
      flex-direction: row;
    }
  }
`

const MiddleWrapper = styled.div`
  border-color: ${brandOrange};
  border-style: solid;
  border-width: 0 0 2px 0;

  .inner {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-bottom: 72px;
    padding-top: 72px;

    @media only screen and (min-width: 48em) {
      flex-direction: row;
      gap: 80px;
    }
  }
`

const BottomWrapper = styled.div`
  border-color: #e1e3db;
  border-style: solid;
  border-width: 0 0 1px 0;
  margin: 0;

  .inner {
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    padding-top: 16px;

    @media only screen and (min-width: 48em) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
`

// Logo
// ----------//
const LogoWrapper = styled.div`
  display: flex;

  img {
    max-width: 200px;
  }

  @media only screen and (min-width: 48em) {
    margin-bottom: 0;
  }
`

// Social List
// ----------//
const SocialLinksList = styled.ul`
  display: flex;
  gap: 8px;
  list-style: none;
  margin: 0 0 16px 0;
  padding: 0;

  @media only screen and (min-width: 48em) {
    margin: 0;
  }
`

const SocialLinksListItem = styled.li`
  margin: 0;
`

const SocialLink = styled.a`
  background-image: url(${({ icon }) => icon});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  height: 24px;
  width: 24px;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
`

const ContactLink = styled.div`
  display: flex;
  gap: 8px;
  margin: 0 0 8px 0;

  a,
  span {
    color: ${brandWhite};
    font-family: ${primaryFont};
    font-size: 16px;
    font-style: normal;
    line-height: 100%; /* 16px */
    text-decoration: none;
    opacity: 0.8;
  }

  a:hover {
    opacity: 1;
  }

  &:last-of-type {
    margin: 0;
  }

  @media only screen and (min-width: 48em) {
    margin: 0;
  }
`

// Nav List
// ----------//

const FooterNavListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
`

const FooterNavList = styled.ul`
  /* gap: 4px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style: none;
  margin: 0 0 40px 0;
  padding: 0;
`

const FooterNavListItem = styled.li`
  text-align: left !important;
  margin: 0;
`

const FooterNavListTitle = styled.li`
  color: ${brandDarkGrey};
  font-family: ${primaryFont};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 16px */
  margin: 0 0 8px 0;
`

// Bottom Nav List
// ----------//
const BottomNavListItemLink = styled(Link)`
  color: ${brandDarkGrey};
  font-family: ${primaryFont};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:visited {
    color: ${brandDarkGrey};
  }
`

const BottomNavList = styled.ul`
  display: flex;
  flex-direction: row;
  gap: 4px;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;

  @media only screen and (min-width: 48em) {
    flex-direction: row;
  }
`

const BottomNavListItem = styled.li`
  margin: 0 0 0 24px;
`

// Publication
// ----------//
const PublicationDetails = styled.span`
  color: ${brandDarkGrey};
  font-family: ${primaryFont};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  margin: 0 0 16px 0;

  @media only screen and (min-width: 48em) {
    margin: 0;
  }
`

// Component Export
//---------------------------------
// export default function GlobalFooter({ test }) {
//   return (
function GlobalFooter() {
  const data = useStaticQuery(graphql`
    query {
      contentfulComponentContacts {
        facebook
        twitter
        linkedin
        instagram
        phone
        email
      }
      allContentfulPageBasicPage {
        edges {
          node {
            pageSlug
            navigationTitle
          }
        }
      }
      contentfulComponentFooterSection {
        publicationDetails
        termOfUseLink
        privacyPolicyLink
        cookiePolicyLink
        footerLogo {
          url
          gatsbyImageData
          title
        }
        firstCategoryTitle
        firstCategoryLinkList {
          id
          linkText
          linkDestination
        }
        secondCategoryTitle
        secondCategoryLinkList {
          id
          linkText
          linkDestination
        }
        thirdCategoryTitle
        thirdCategoryLinkList {
          id
          linkText
          linkDestination
        }
        fourthCategoryTitle
        fourthCategoryLinkList {
          id
          linkText
          linkDestination
        }
        fifthCategoryTitle
        fifthCategoryLinkList {
          id
          linkText
          linkDestination
        }
      }
    }
  `)

  const facebookLink = data?.contentfulComponentContacts?.facebook || '#'
  const linkedinLink = data?.contentfulComponentContacts?.linkedin || '#'
  const twitter = data?.contentfulComponentContacts?.twitter || '#'
  const instagram = data?.contentfulComponentContacts?.instagram || '#'
  const phone = data?.contentfulComponentContacts?.phone || ''
  const email = data?.contentfulComponentContacts?.email || ''
  const publicationDetails = data?.contentfulComponentFooterSection?.publicationDetails || ''
  const termOfUseLink = data?.contentfulComponentFooterSection?.termOfUseLink || ''
  const privacyPolicyLink = data?.contentfulComponentFooterSection?.privacyPolicyLink || ''
  const cookiePolicyLink = data?.contentfulComponentFooterSection?.cookiePolicyLink || ''
  const footerLogo = data?.contentfulComponentFooterSection?.footerLogo.gatsbyImageData
  const footerLogoTitle = data?.contentfulComponentFooterSection?.footerLogo.title
  const footerLinkCategories = [
    {
      categoryTitle: data?.contentfulComponentFooterSection?.firstCategoryTitle,
      footerLinkList: data?.contentfulComponentFooterSection?.firstCategoryLinkList,
    },
    {
      categoryTitle: data?.contentfulComponentFooterSection?.secondCategoryTitle,
      footerLinkList: data?.contentfulComponentFooterSection?.secondCategoryLinkList,
    },
    {
      categoryTitle: data?.contentfulComponentFooterSection?.thirdCategoryTitle,
      footerLinkList: data?.contentfulComponentFooterSection?.thirdCategoryLinkList,
    },
    {
      categoryTitle: data?.contentfulComponentFooterSection?.fourthCategoryTitle,
      footerLinkList: data?.contentfulComponentFooterSection?.fourthCategoryLinkList,
    },
    {
      categoryTitle: data?.contentfulComponentFooterSection?.fifthCategoryTitle,
      footerLinkList: data?.contentfulComponentFooterSection?.fifthCategoryLinkList,
    },
  ].filter(({ categoryTitle }) => categoryTitle)

  return (
    <GlobalFooterWrapper>
      <TopWrapper>
        <div className="outer">
          <div className="inner">
            <SocialLinksList>
              <SocialLinksListItem>
                <SocialLink href={linkedinLink} icon={LinkedIconWhite} aria-label="linked in" />
              </SocialLinksListItem>
              <SocialLinksListItem>
                <SocialLink href={facebookLink} icon={FacebookIconWhite} aria-label="facebook" />
              </SocialLinksListItem>
              <SocialLinksListItem>
                <SocialLink href={twitter} icon={TwitterIconWhite} aria-label="twitter" />
              </SocialLinksListItem>
              <SocialLinksListItem>
                <SocialLink href={instagram} icon={InstagramIconWhite} aria-label="instagram" />
              </SocialLinksListItem>
            </SocialLinksList>
            <ContactLink>
              <span>Phone us:</span>
              <a href={`tel:${phone}`}>{formatPhoneNumber(phone)}</a>
            </ContactLink>
            <ContactLink>
              <span>Email us:</span>
              <a href={`mailto:${email}`}>{email}</a>
            </ContactLink>
          </div>
        </div>
      </TopWrapper>
      <MiddleWrapper>
        <div className="outer">
          <div className="inner">
            <LogoWrapper>
              <GatsbyImage objectFit="contain" image={footerLogo} alt={footerLogoTitle} />
            </LogoWrapper>
            {footerLinkCategories.length > 0 &&
              footerLinkCategories.map(({ categoryTitle, footerLinkList }) => (
                <FooterNavListWrapper key={categoryTitle}>
                  <FooterNavList>
                    <FooterNavListTitle>{categoryTitle}</FooterNavListTitle>
                    {footerLinkList &&
                      footerLinkList.map(({ id: linkId, linkText, linkDestination }) => (
                        <FooterNavListItem key={linkId}>
                          <LinkAtom
                            linkType="Footer Link"
                            target={
                              isOuterLink(getCalloutLink(linkDestination)) ? '_blank' : '_self'
                            }
                            linkDestination={getCalloutLink(linkDestination)}
                            linkText={linkText}
                          />
                        </FooterNavListItem>
                      ))}
                  </FooterNavList>
                </FooterNavListWrapper>
              ))}
          </div>
        </div>
      </MiddleWrapper>
      <BottomWrapper>
        <div className="outer">
          <div className="inner">
            <PublicationDetails>{publicationDetails}</PublicationDetails>
            <BottomNavList>
              <BottomNavListItem>
                <BottomNavListItemLink to={termOfUseLink}>Term of Use</BottomNavListItemLink>
              </BottomNavListItem>
              <BottomNavListItem>
                <BottomNavListItemLink to={privacyPolicyLink}>Privacy Policy</BottomNavListItemLink>
              </BottomNavListItem>
              <BottomNavListItem>
                <BottomNavListItemLink to={cookiePolicyLink}>Cookie Policy</BottomNavListItemLink>
              </BottomNavListItem>
            </BottomNavList>
          </div>
        </div>
      </BottomWrapper>
    </GlobalFooterWrapper>
  )
}

export default GlobalFooter
