import { createStorefrontApiClient } from '@shopify/storefront-api-client'
import isomorphicFetch from 'isomorphic-fetch'
import { customerCreateMutation } from './customerCreateMutation'
import { customerAccessTokenCreateMutation } from './customerAccessTokenCreateMutation'
import { customerQuery } from './customerQuery'
import { customerPasswordRecoverMutation } from './customerPasswordRecoverMutation'
import { customerPasswordResetMutation } from './customerPasswordResetMutation'
import { customerAddressDeleteMutation } from './customerAddressDeleteMutation'
import { customerAddressCreateMutation } from './customerAddressCreateMutation'
import { customerAddressUpdateMutation } from './customerAddressUpdateMutation'
import { customerOrdersQuery } from './customerOrdersQuery'
import { customerAddressesQuery } from './customerAddressesQuery'
import { customerDefaultAddressUpdateMutation } from './customerDefaultAddressUpdateMutation'
import { customerUpdateMutation } from './customerUpdateMutation'
import getExternalProductId from '../../helpers/getExternalProductId'
import { customerActivateMutation } from './customerActivateMutation'

const client = createStorefrontApiClient({
  storeDomain: process.env.GATSBY_SHOPIFY_STORE_URL,
  apiVersion: '2024-04',
  publicAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  customFetchApi: isomorphicFetch,
})

const createCustomer = async (input) => {
  try {
    const response = await client
      .request(customerCreateMutation, {
        variables: {
          input,
        },
      })
      .catch((e) => {
        console.log('catch ===', e)
      })

    return response.data.customerCreate
  } catch (e) {
    console.error('Error creating customer', e)
  }
}

const loginCustomer = async (input) => {
  try {
    const response = await client.request(customerAccessTokenCreateMutation, {
      variables: {
        input, // email and password
      },
    })

    return response.data.customerAccessTokenCreate
  } catch (e) {
    console.error('Error logging in customer', e)
  }
}

const activateCustomer = async ({ customerId, activationToken, password }) => {
  try {
    const response = await client
      .request(customerActivateMutation, {
        variables: {
          id: `gid://shopify/Customer/${customerId}`,
          input: {
            activationToken,
            password,
          },
        },
      })
      .catch((e) => {
        console.log('catch ===', e)
      })

    return response.data.customerActivate
  } catch (e) {
    console.error('Error creating customer', e)
  }
}

const getCustomer = async (accessToken) => {
  try {
    const response = await client.request(customerQuery, {
      variables: {
        input: accessToken,
      },
    })

    return response.data.customer
  } catch (e) {
    console.error('Error getting customer', e)
  }
}

const getCustomerOrders = async ({ accessToken, numProducts, isBackward, cursor = null }) => {
  try {
    const response = await client.request(customerOrdersQuery(isBackward), {
      variables: {
        input: accessToken,
        numProducts,
        cursor,
      },
    })

    return {
      orders: response.data.customer?.orders,
      numberOfOrders: response.data.customer?.numberOfOrders,
      pageInfo: response.data.customer?.orders.pageInfo,
    }
  } catch (e) {
    console.error('Error getting customer', e)
  }
}

const getCustomerOrdersWithProducts = async ({ accessToken, cursor }) => {
  try {
    const response = await client.request(
      `
    query customerQuery($input: String!, $cursor: String) {
    customer(customerAccessToken: $input) {
    orders(first: 20, after: $cursor) {
      edges {
        node {
          fulfillmentStatus
          lineItems(first: 20) {
            edges {
              node {
                title
                variant {
                  id
                }
              }
            }
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      } 
    }
  }
}
    `,
      {
        variables: {
          input: accessToken,
          cursor,
        },
      }
    )

    return response.data
  } catch (e) {
    console.error('Error getting customer', e)
  }
}

const getAllCustomerProductsWithOrders = async ({ accessToken }) => {
  let isOrderElse = true
  let nextCursor = null
  let customerOrders = []

  while (isOrderElse) {
    const response = await getCustomerOrdersWithProducts({ accessToken, cursor: nextCursor })

    if (!response.customer || !response.customer.orders || response.customer.orders.length === 0)
      break

    const {
      pageInfo: { hasNextPage, endCursor },
      edges,
    } = response.customer.orders

    isOrderElse = hasNextPage
    nextCursor = endCursor
    customerOrders = [...customerOrders, ...edges]
  }

  return customerOrders
}

const isProductPurchasedByCustomer = async ({ accessToken, variantsIdArray }) => {
  const customerOrders = await getAllCustomerProductsWithOrders({ accessToken })

  if (customerOrders.lenght === 0) return false

  const fulfilledOrders = customerOrders.filter(
    ({ node: { fulfillmentStatus } }) => fulfillmentStatus === 'FULFILLED'
  )

  if (fulfilledOrders.length === 0) return false

  const purchasedProducts = fulfilledOrders.map(({ node }) => node.lineItems.edges).flat()

  const idArray = purchasedProducts.map(
    ({ node: { variant } }) => variant && getExternalProductId(variant.id)
  )

  const isProductPurchased = variantsIdArray.some((id) =>
    idArray.some((variantId) => variantId === getExternalProductId(id))
  )

  return isProductPurchased
}

const getCustomerOrderLineItems = async (accessToken, orderId) => {
  try {
    const response = await client.request(
      `
    query customerQuery($input: String!) {
    customer(customerAccessToken: $input) {
    orders(first: 1, query: "id:${orderId}") {
      edges {
        node {
          id
          orderNumber
          fulfillmentStatus
          financialStatus
          billingAddress {
            address1
            address2
            city
            name
            country
            zip
          }
          shippingAddress {
            address1
            address2
            city
            name
            country
            zip
          }
          totalPrice {
            amount
          }            
          lineItems(first: 20) {
            edges {
              node {
                title
                quantity
                discountedTotalPrice {
                  amount
                }
                variant {
                  id
                  image {
                    url
                  }
                  price {
                    amount
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `,
      {
        variables: {
          input: accessToken,
        },
      }
    )

    return response.data.customer?.orders?.edges[0]?.node
  } catch (e) {
    console.error('Error getting customer', e)
  }
}

const getCustomerAddresses = async (accessToken) => {
  try {
    const response = await client.request(customerAddressesQuery, {
      variables: {
        input: accessToken,
      },
    })

    return response.data.customer.addresses
  } catch (e) {
    console.error('Error getting customer', e)
  }
}

const recoverPassword = async (email) => {
  try {
    const response = await client.request(customerPasswordRecoverMutation, {
      variables: {
        email,
      },
    })

    return response.data.customerRecover
  } catch (e) {
    console.error('Error getting customer', e)
  }
}

const resetPassword = async (resetUrl, password) => {
  try {
    const response = await client.request(customerPasswordResetMutation, {
      variables: {
        resetUrl,
        password,
      },
    })

    return response.data.customerResetByUrl
  } catch (e) {
    console.error('Error getting customer', e)
  }
}

const deleteAddress = async (accessToken, id) => {
  try {
    const response = await client.request(customerAddressDeleteMutation, {
      variables: {
        customerAccessToken: accessToken,
        id,
      },
    })

    return response.data.customerAddressDelete
  } catch (e) {
    console.error('Error getting customer', e)
  }
}

const createAddress = async (accessToken, address) => {
  try {
    const response = await client.request(customerAddressCreateMutation, {
      variables: {
        customerAccessToken: accessToken,
        address,
      },
    })

    return response.data.customerAddressCreate
  } catch (e) {
    console.error('Error getting customer', e)
  }
}

const updateAddress = async (accessToken, address, addressId) => {
  try {
    console.log({
      variables: {
        customerAccessToken: accessToken,
        address,
        id: addressId,
      },
    })

    const response = await client.request(customerAddressUpdateMutation, {
      variables: {
        customerAccessToken: accessToken,
        address,
        id: addressId,
      },
    })

    return response.data.customerAddressUpdate
  } catch (e) {
    console.error('Error getting customer', e)
  }
}

const updateDefaultAddress = async (accessToken, addressId) => {
  try {
    const response = await client.request(customerDefaultAddressUpdateMutation, {
      variables: {
        customerAccessToken: accessToken,
        addressId,
      },
    })

    return response.data.customerDefaultAddressUpdate
  } catch (e) {
    console.error('Error getting customer', e)
  }
}

const updateCustomer = async (customer, customerAccessToken) => {
  try {
    const response = await client.request(customerUpdateMutation, {
      variables: {
        customer,
        customerAccessToken,
      },
    })

    return response.data.customerUpdate
  } catch (e) {
    console.error('Error getting customer', e)
  }
}

export const customerClient = {
  activateCustomer,
  createCustomer,
  loginCustomer,
  getCustomer,
  updateCustomer,
  getCustomerOrders,
  getCustomerOrderLineItems,
  getCustomerAddresses,
  recoverPassword,
  resetPassword,
  deleteAddress,
  createAddress,
  updateAddress,
  updateDefaultAddress,
  getAllCustomerProductsWithOrders,
  getCustomerOrdersWithProducts,
  isProductPurchasedByCustomer,
}
