// Module Import
//---------------------------------
import 'slick-carousel/slick/slick.scss'
import '../../../assets/css/slick-theme.scss'

import React from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import ImageCut from '../../../assets/images/graphic_imageCut.svg'
import Review from '../TrustedReviews/TrustedReviewsItem'

// Component Import
//---------------------------------

// Styled Component Declarations
//---------------------------------
const GalleryCarouselWrapper = styled.li`
  border-radius: 4px;
  position: relative;
  height: 100%;
  width: 100%;

  &:after {
    background-image: url(${ImageCut});
    background-repeat: no-repeat;
    background-size: 100%;
    bottom: 0;
    content: '';
    height: 30px;
    position: absolute;
    right: 0;
    width: 30px;
    z-index: 1;
  }

  @media only screen and (min-width: 48em) {
    &:after {
      background-image: url(${ImageCut});
      background-position: bottom right;
      background-size: cover;
      bottom: 0;
      content: '';
      height: 60px;
      position: absolute;
      right: 0;
      width: 60px;
      z-index: 1;
    }
  }

  .slick-slider {
    display: flex;
    flex-direction: column;
    height: 100%;

    .slick-dots {
      /* display: flex !important; */
      /* justify-content: center;
      margin: 0; */
    }

    .slick-track {
      height: 100%;

      .slick-slide > div {
        height: 100%;
      }
    }
  }
`

const Slide = styled.div`
  padding: 4px;
  height: 100%;

  display: flex !important;
  align-items: center;
  justify-content: center;
`

// Component Export
//---------------------------------
export default function GoogleReviewCarousel({ reviews }) {
  const carouselSettings = {
    dots: true,
    infinite: reviews.length > 4,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplaySpeed: 4000,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1570,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <GalleryCarouselWrapper>
      <Slider {...carouselSettings}>
        {reviews.map((target, index) => (
          <Slide key={index}>
            <Review {...target} />
          </Slide>
        ))}
      </Slider>
    </GalleryCarouselWrapper>
  )
}

GoogleReviewCarousel.propTypes = {}
